<template>
  <Header />
  <div class="container is-max-desktop no-indent">
    <a href="booking/opret" class="booking-create">
        <i class="fas fa-plus-circle"></i>
      <span class="is-sr-only">Opret</span>
    </a>
    <vue-cal 
        v-if="global.user.hasOwnProperty('id')"
        :events="events" 
        :on-event-click="bookingOpen"
        :split-days="splitDays"
        :min-cell-width="minCellWidth"
        :min-split-width="minSplitWidth"

        locale="da"
        :disable-views="['years', 'year']"
        active-view="day"
        :selected-date="(currentRoute.query.selectedDate != null ? this.$route.query.selectedDate : Date())" 
        :time-from="0" 
        events-count-on-year-view="events-count-on-year-view" 
        events-on-month-view="short"
      >
      <template v-slot:no-event="">&nbsp;</template>
    </vue-cal>
  </div>
  <div class="container is-max-desktop">
    <LoginStatus />
  </div>
  <Footer/>
</template>

<script>
import ax from 'axios'
export const axios = ax

import { defineComponent } from 'vue'
import { useComposition } from '@/compositions/composition'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import LoginStatus from '@/components/LoginStatus.vue'
import VueCal from 'vue-cal'
import 'vue-cal/dist/i18n/da.js'
import 'vue-cal/dist/vuecal.css'

export default defineComponent({
  setup() {
    const { global } = useComposition();
    return {
        global,
        apiRoot: process.env.VUE_APP_API_ROOT
    }
  },
  data() {
    return {
      minCellWidth: 130,
      minSplitWidth: 0,
      splitDays: [
        { id: 1, class: 'benzin', label: 'Benzin' },
        { id: 43, class: 'el', label: 'El' }
      ],
      events: []
    }
  },
  components: {
    Header,
    Footer,
    LoginStatus,
    VueCal 
  },
  created() {
      this.bookingsLoad();
  },
  mounted() {
      this.setCalendarHeight();
  },
  methods: {
    bookingsLoad() {
      axios.get(process.env.VUE_APP_API_ROOT + '/bookings.php')
        .then(response => {
            this.events = response.data;
        })
    },
    bookingOpen(event, e) {
      //location.href = '/booking/' + event.id;
      this.global.navigation.navigatedFrom = 'Calendar';
      this.$router.push('/booking/' + event.id);
      e.stopPropagation()
    },
    setCalendarHeight() {
      var appHeader = document.getElementsByTagName('header');
      var vueCalHeader = document.getElementsByClassName('vuecal__header');
      var vueCalBody = document.getElementsByClassName('vuecal__body');
      
      if(appHeader.length > 0 && vueCalHeader.length > 0 && vueCalBody.length > 0) {
        let heightAppHeader = appHeader[0].offsetHeight;
        let heightVueCalHeader = vueCalHeader[0].offsetHeight;
        let heightVueCalBody = vueCalBody[0].offsetHeight;

        let newHeightVueCalBody = window.innerHeight - (heightAppHeader + heightVueCalHeader);
        vueCalBody[0].setAttribute("style", 'height: ' + newHeightVueCalBody + 'px');
      }
    }
  },
  computed: {
    currentRoute() {
        return this.$route;
    }
  }
})

</script>